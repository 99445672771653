import { eventBus } from '@paradigm-studio/core';
import { InMemoryCache, NormalizedCacheObject } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { createHttpLink } from 'apollo-link-http';
import { GeneralErrorEventListenerName } from 'rootShared/Constants';
import { customizeGraphQLErrors } from './utils/graphQLErrorExtension';

const setClient = (): ApolloClient<NormalizedCacheObject> => {
    const apolloServerUrl = window.location.protocol + '//' + window.location.hostname + '/api/pk';

    const httpLink = createHttpLink({
        uri: apolloServerUrl,
    });

    const errorLink = onError(({ graphQLErrors, networkError }) => {
        const error = customizeGraphQLErrors(undefined, graphQLErrors);

        if (error) {
            eventBus.dispatch(GeneralErrorEventListenerName.DISPLAY_GENERAL_ERROR, error);

            if (error.message) {
                console.log(`[Error message]: ${error.message}`);
            }
            if (error.graphQLErrors?.length) {
                error.graphQLErrors.forEach(({ message, locations, path, extensions }): void => {
                    console.log(
                        `[GraphQL error]:\r\n\tMessage: ${message}\r\n\tLocation: `,
                        locations,
                        '\r\n\tPath:',
                        path,
                    );

                    if (extensions) {
                        console.log(`[GraphQL error extensions]:\r\n\tCode: ${extensions.code}`);
                        if (extensions.data) {
                            const data = extensions.data;
                            console.log(
                                `[GraphQL error extensions data]:\r\n\tID: ${data.id}\r\n\tErrorCode: ${data.errorCode}\r\n\tMetadata: `,
                                data.metadata,
                            );
                        }
                    }
                });
            }
        }

        if (networkError) {
            console.log(`[Network error]: ${networkError}`);
        }
    });

    const links = [errorLink, httpLink];
    const link = ApolloLink.from(links);

    return new ApolloClient({
        link,
        cache: new InMemoryCache(),
        defaultOptions: { query: { fetchPolicy: 'network-only', errorPolicy: 'all' } },
    });
};

export const client = setClient();
