import { ApolloError } from 'apollo-boost';
import { GraphQLError } from 'graphql';

interface GraphQLErrorExtension<T> extends GraphQLError {
    extensions: {
        code: string;
        data?: {
            id: string;
            errorCode: string;
            metadata: T;
        };
    };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface GraphQLExtendedError<KMetadata = { [key: string]: any }> {
    message?: string;
    graphQLErrors?: ReadonlyArray<GraphQLErrorExtension<KMetadata>>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    networkError?: any;
}

function getErrorMessage(graphQLErrors?: ReadonlyArray<GraphQLError>, message?: string): string | undefined {
    if (message) {
        return message;
    } else if (graphQLErrors?.length) {
        return graphQLErrors[0].message;
    }
}

/**
 * Use this interface to customize the result of a GraphQL
 * @param TData Type of the data result
 * @param [ Optional ] Type of metadata error ( GraphQLError.extensions.data.metadata )
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface ResultGraphQLExtension<TData, KMetadata = { [key: string]: any }> {
    data?: TData;
    error?: GraphQLExtendedError<KMetadata>;
}

/**
 * Use this interface to customize the result of a React GraphQL
 * @param TData Type of the data result
 * @param [ Optional ] Type of metadata error ( GraphQLError.extensions.data.metadata )
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface ReactResultGraphQLExtension<TData, KMetadata = { [key: string]: any }>
    extends ResultGraphQLExtension<TData, KMetadata> {
    loading: boolean;
}

/**
 * Type GraphQLError.extensions to a usable object.
 * [ Optional ] We can type GraphQLError.extensions.data.metadata with generic type TMetadata
 *
 * @param graphQLErrors The GraphQL Errors resulted from Backend GraphQL call
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const customizeGraphQLErrors = function <TMetadata = { [key: string]: any }>(
    apolloError?: ApolloError,
    graphQLErrors?: ReadonlyArray<GraphQLError>,
): GraphQLExtendedError<TMetadata> | undefined {
    const errors = apolloError?.graphQLErrors || graphQLErrors;
    const graphQLErrorsExtension: ReadonlyArray<GraphQLErrorExtension<TMetadata>> | undefined = errors as
        | ReadonlyArray<GraphQLErrorExtension<TMetadata>>
        | undefined;
    const messageError = apolloError?.message;
    if (graphQLErrorsExtension || messageError) {
        return {
            message: getErrorMessage(graphQLErrorsExtension, messageError),
            graphQLErrors: graphQLErrorsExtension,
            networkError: apolloError?.networkError,
        };
    }
};
