import { InjectIntlContext } from '@comparaonline/react-intl-hooks';
import { faTools } from '@fortawesome/pro-duotone-svg-icons';
import { App, applicationStore, history, LocaleContext } from '@paradigm-studio/core';
import { Observer, useAsObservableSource } from 'mobx-react-lite';
import React from 'react';
import ReactDOM from 'react-dom';
import { IntlProvider } from 'react-intl';
import { Route, RouteComponentProps, Router, Switch } from 'react-router-dom';
import 'semantic-ui-less/semantic.less';
import { pkToolsApplicationStructure } from './ApplicationRoute/PKToolsApplication/structure';
import { GlobalSearch } from './GlobalSearch';
import * as serviceWorker from './serviceWorker';
import { messages } from './translations/translation';
import './webix.css';

const PKToolsApplication = React.lazy(
    (): Promise<{ default: React.ComponentType<RouteComponentProps> }> =>
        import(/* webpackChunkName: 'PKToolsModule' */ './ApplicationRoute/PKToolsApplication/PKToolsApplication'),
);

applicationStore.registry.register(
    'applications.PKTools',
    '/pk',
    'fontawesome',
    faTools,
    PKToolsApplication,
    pkToolsApplicationStructure,
    GlobalSearch,
);

const Index = (): React.ReactElement => {
    const localeStore = useAsObservableSource(React.useContext(LocaleContext));

    return (
        <Observer>
            {(): React.ReactElement => (
                <IntlProvider locale={localeStore.locale} messages={messages[localeStore.locale.split(/[-_]/)[0]]}>
                    <InjectIntlContext>
                        <Router history={history}>
                            <Switch>
                                <Route path="/" component={App} />
                            </Switch>
                        </Router>
                    </InjectIntlContext>
                </IntlProvider>
            )}
        </Observer>
    );
};

ReactDOM.render(<Index />, document.getElementById('root') as HTMLElement);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
