import { SearchResult } from '@paradigm-studio/core';
import { localeStore } from '@paradigm-studio/core/lib/context/LocaleStore';
import { ApolloQueryResult, gql } from 'apollo-boost';
import { client } from 'apolloClient';
import * as React from 'react';
import { IntlProvider } from 'react-intl';
import { messages } from './translations/translation';

interface QueryResult {
    globalSearch: {
        searchId: string;
        snippet: string;
        type: string;
    }[];
}

const GET_RESULTS = gql`
    query($searchString: String) {
        globalSearch(searchString: $searchString) {
            searchId: id
            snippet
            type
        }
    }
`;

export const GlobalSearch = async (searchString: string): Promise<SearchResult[]> => {
    const { intl } = new IntlProvider(
        { locale: localeStore.locale, messages: messages[localeStore.locale.split(/[-_]/)[0]] },
        {},
    ).getChildContext();
    if (searchString.length > 2) {
        const searchResults: ApolloQueryResult<QueryResult> = await client.query({
            query: GET_RESULTS,
            variables: { searchString },
        });
        return searchResults.data.globalSearch.map(
            (searchResult): SearchResult => {
                const { searchId, type } = searchResult;
                const re = new RegExp(searchString, 'gi');
                const hightLightSnippet = searchResult.snippet.replace(re, '<b>$&</b>');
                return {
                    category: type,
                    name: intl.formatMessage({ id: 'SearchCategory.' + type }),
                    url: `/pk/${type.toLowerCase()}/${searchId}`,
                    template: (
                        <div style={{ padding: '10px' }} dangerouslySetInnerHTML={{ __html: hightLightSnippet }}></div>
                    ),
                };
            },
        );
    } else {
        return [];
    }
};
