import { faBallotCheck, faHome, faList, faSitemap, faTable } from '@fortawesome/pro-light-svg-icons';
import { ApplicationItem } from '@paradigm-studio/core';

export const pkToolsApplicationStructure: ApplicationItem[] = [
    {
        title: 'PKToolsApplication.menuLabels.home',
        route: '/',
        icon: faHome,
    },
    {
        title: 'PKToolsApplication.menuLabels.templateDefinitions',
        icon: faList,
        children: [
            {
                title: 'PKToolsApplication.menuLabels.series',
                route: '/series',
            },
            {
                title: 'PKToolsApplication.menuLabels.productTypes',
                route: '/producttype',
            },
            {
                title: 'PKToolsApplication.menuLabels.shapes',
                route: '/shape',
            },
            {
                title: 'PKToolsApplication.menuLabels.productConfigurations',
                route: '/productconfiguration',
            },
            {
                title: 'PKToolsApplication.menuLabels.subUnitTypes',
                route: '/subunittype',
            },
            {
                title: 'PKToolsApplication.menuLabels.seriesFamilies',
                route: '/seriesfamily',
            },
        ],
    },
    {
        title: 'PKToolsApplication.menuLabels.productTree',
        route: '/producttree',
        icon: faSitemap,
    },
    {
        title: 'PKToolsApplication.menuLabels.questionAndDimensionAssignment',
        icon: faTable,
        children: [
            {
                title: 'PKToolsApplication.menuLabels.questionAndDimensionAssignment.grilleApp',
                route: '/qda/grilleapp',
            },
            {
                title: 'PKToolsApplication.menuLabels.questionAndDimensionAssignment.window',
                route: '/qda/window',
            },
            {
                title: 'PKToolsApplication.menuLabels.questionAndDimensionAssignment.wrapping',
                route: '/qda/wrapping',
            },
            {
                title: 'PKToolsApplication.menuLabels.questionAndDimensionAssignment.windowDimension',
                route: '/qda/windowdimension',
            },
            {
                title: 'PKToolsApplication.menuLabels.questionAndDimensionAssignment.windowSetDimension',
                route: '/qda/windowsetdimension',
            },
            {
                title: 'PKToolsApplication.menuLabels.questionAndDimensionAssignment.windowSetLayout',
                route: '/qda/windowsetlayout',
            },
        ],
    },
    {
        title: 'PKToolsApplication.menuLabels.rules',
        icon: faBallotCheck,
        children: [
            {
                title: 'PKToolsApplication.menuLabels.rules.limitation',
                icon: faList,
                children: [
                    {
                        title: 'PKToolsApplication.menuLabels.rules.limitation.window',
                        route: '/rules/limitation/window',
                    },
                    {
                        title: 'PKToolsApplication.menuLabels.rules.limitation.windowset',
                        route: '/rules/limitation/windowset',
                    },
                    {
                        title: 'PKToolsApplication.menuLabels.rules.limitation.wrapping',
                        route: '/rules/limitation/wrapping',
                    },
                    {
                        title: 'PKToolsApplication.menuLabels.rules.limitation.configuration',
                        route: '/rules/limitation/configuration',
                    },
                ],
            },
            {
                title: 'PKToolsApplication.menuLabels.rules.qaFiltering',
                icon: faList,
                children: [
                    {
                        title: 'PKToolsApplication.menuLabels.rules.qaFiltering.window',
                        route: '/rules/question-answer-filtering/window',
                    },
                    {
                        title: 'PKToolsApplication.menuLabels.rules.qaFiltering.grilleApp',
                        route: '/rules/question-answer-filtering/grille_app',
                    },
                    {
                        title: 'PKToolsApplication.menuLabels.rules.qaFiltering.wrapping',
                        route: '/rules/question-answer-filtering/wrapping',
                    },
                ],
            },
        ],
    },
];
