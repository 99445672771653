import { translations } from '@paradigm-studio/core';
import { addLocaleData } from 'react-intl';
import enLocaleData from 'react-intl/locale-data/en';
import frLocaleData from 'react-intl/locale-data/fr';
import jaLocaleData from 'react-intl/locale-data/ja';
import ruLocaleData from 'react-intl/locale-data/ru';
import pkEn from '../translations/en.json';
import pkFr from '../translations/fr.json';
import pkJa from '../translations/ja.json';
import pkRu from '../translations/ru.json';

addLocaleData([...enLocaleData, ...frLocaleData, ...jaLocaleData, ...ruLocaleData]);
export const messages: { [key: string]: { [key: string]: string } } = {
    fr: { ...translations['fr'], ...pkFr },
    en: { ...translations['en'], ...pkEn },
    ja: { ...translations['ja'], ...pkJa },
    ru: { ...translations['ru'], ...pkRu },
};
